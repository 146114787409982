<template>
    <wait-for-resource :resource="leagueMatchdays">
        <!--
            Only show league schedule if the league's matchdays information
            have been filled.
        -->
        <span v-if="!league.has_started">
            {{ $t('leagues.messages.schedule_not_ready') }}
        </span>

        <b-row
            v-else
            align-h="center"
        >
            <b-col
                lg="9"
                xl="8"
                class="px-0 px-md-3"
            >
                <div class="card-stack">
                    <league-matchday-card
                        v-for="leagueMatchday in leagueMatchdays.models"
                        :key="leagueMatchday.id"
                        :league-matchday="leagueMatchday"
                        :preopen="league.current_league_matchday
                            && leagueMatchday.id === league.current_league_matchday.id"
                    />
                </div>
            </b-col>
        </b-row>
    </wait-for-resource>
</template>

<script>
import {League} from '@/models/League';
import {LeagueMatchdays} from '@/models/LeagueMatchday';
import LeagueStatus from '@/library/enums/LeagueStatus';
import LeagueMatchdayCard from '@/components/leagueMatchdays/Card';

export default {
    name: 'LeaguesScheduleIndex',
    components: {LeagueMatchdayCard},
    props: {
        league: {
            type: League,
            required: true,
        },
    },
    data() {
        return {
            LeagueStatus,
            leagueMatchdays: [],
        };
    },
    async created() {
        this.leagueMatchdays = new LeagueMatchdays([], {
            routePrefix: this.league.getFetchURL(),
        });

        if (this.league.has_started) {
            await this.leagueMatchdays.fetch();
        }
    },
};
</script>
